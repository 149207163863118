import React from "react";
import {
  BrainCircuit,
  Calendar,
  Camera,
  CarFront,
  Laptop,
  Palette,
  Route,
  UserRound,
  Users,
  GraduationCap,
  Video,
} from "lucide-react";
import { kidpointingdown, zoomcall } from "../../assets/images";
import styles from "../../pages/styles";

const FreeTrialAbout = () => {
  return (
    <div className="bg-blueprimary flex flex-col items-center justify-center w-full md:py-10 py-10 relative">
      <div className="text-center max-w-4xl px-2">
        <h2 className={`${styles.biggerheadingdark} mb-4`}>
          We Teach Virtually
        </h2>
        <p className={`${styles.paragraph} mb-8`}>
          The Program is taught by expert instructors dedicated to providing
          high-quality, accessible education right to your screen
        </p>
      </div>
      <div className="flex flex-wrap justify-evenly items-start w-full md:px-24">
        <BenefitCard
          title="Flexible Scheduling"
          img=<Calendar className="w-12 h-12" />
        />
        <BenefitCard
          title="Expert Instructors"
          img=<GraduationCap className="w-12 h-12" />
        />
        <BenefitCard
          title="No Commuting Required"
          img=<CarFront className="w-12 h-12" />
        />
        <BenefitCard title="Quick Setup" img=<Laptop className="w-12 h-12" /> />
        <BenefitCard
          title="Taught on Zoom"
          img=<Video className="w-12 h-12" />
        />
      </div>
    </div>
  );
};

const BenefitCard = ({ title, desc, img }) => {
  return (
    <div className="flex flex-col justify-center text-center max-w-[200px]">
      <div
        className={`${styles.paragraph} flex flex-col gap-2 items-center p-2`}
      >
        {img}
        <p className={`${styles.paragraph} font-semibold text-lg mb-0`}>
          {title}
        </p>
        <p className={`${styles.paragraph} mb-0`}>{desc}</p>
      </div>
    </div>
  );
};

export default FreeTrialAbout;
