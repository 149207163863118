import React from "react";
import { Briefcase, Star } from "lucide-react";
import styles from "../../pages/styles";
import { activision, disney, hasbro, lego, riotgames } from "../../assets/images";

const InstructorExperience = () => {
  return (
    <div className="bg-darkprimary flex flex-col items-center justify-center w-full md:py-10 py-10 relative">
      <div className="text-center max-w-4xl px-2">
        <h2 className={`${styles.biggerheadingwhite} mb-4`}>
         Learn From the Best!
        </h2>
        <p className={`${styles.paragraphwhite} mb-8`}>
          Our instructors have worked with some of the biggest names in gaming, animation, and design. Learn from the experts who have been part of creating your favorite games and animations!
        </p>
      </div>
      <div className="flex flex-wrap justify-evenly items-start w-full md:px-24">
        <ExperienceCard
          img=<img src={disney} alt="Disney Logo" className="w-32" />
        />
        <ExperienceCard
          img=<img src={activision} alt="Activision Logo" className="w-36 pt-2" />
        />
        <ExperienceCard
          img=<img src={riotgames} alt="Riot Games Logo" className="w-32" />
        />
        <ExperienceCard
          img=<img src={hasbro} alt="Hasbro Logo" className="w-28" />
        />
        <ExperienceCard
          img=<img src={lego} alt="Lego Logo" className="w-24 -mt-2" />
        />
      </div>
    </div>
  );
};

const ExperienceCard = ({ company, img }) => {
  return (
    <div className="flex flex-col justify-center text-center max-w-[200px]">
      <div
        className={`${styles.paragraph} flex flex-col gap-2 items-center p-2`}
      >
        {img}
        <p className={`${styles.paragraph} font-semibold text-lg mb-0`}>
          {company}
        </p>
      </div>
    </div>
  );
};

export default InstructorExperience;
