import React from "react";
import styles, { elements } from "../../../pages/styles";

const TryBefore = () => {
  return (
    <div className="flex flex-col md:pt-14 gap-3 pt-12 md:px-40 px-4 items-center bg-pinkprimary">
      <h1 className={`${styles.biggerheadingdark} text-center`}>
        Try Before{" "}
        <span className="underline decoration-blueprimary decoration-8">
          Anything!
        </span>
      </h1>
      <p className={`${styles.paragraph} text-center`}>
        Uncertain if our class or program is the perfect match for your child?
        Experience it firsthand with a complimentary trial session, completely
        risk-free.
      </p>
      {/* <div>
        <a href="/pages/freetrial">
          <button className={`${elements.bluebutton}`}>
            Schedule Trial Class
          </button>
        </a>
      </div> */}
    </div>
  );
};

export default TryBefore;
